import React from 'react';
import {ListGroup, Col, Row, Spinner} from 'react-bootstrap'
import { Icon } from 'react-icons-kit';
import DatePicker from 'react-date-picker'
import {iosArrowBack} from 'react-icons-kit/ionicons/iosArrowBack'
import {androidArrowDropright} from 'react-icons-kit/ionicons/androidArrowDropright'
import {androidArrowDropleft} from 'react-icons-kit/ionicons/androidArrowDropleft'

import api from '../services/api'
import {dateSql} from '../Utils';

import '../css/Default.css';


class Agenda extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            items : [],
            date: new Date(),
            loading: false,
            user: {
                pk_med: 0,
                name: '',
                title: ''
            }
        };
        this.mapItems = this.mapItems.bind(this)
        this.clickList = this.clickList.bind(this)
        this.openItem = this.openItem.bind(this)
        this.backButton = this.backButton.bind(this)
        this.onChange = this.onChange.bind(this)
        this.setDate = this.setDate.bind(this)
    }


    clickList(e) {
        e.preventDefault();
    }

    openItem(e, item, id) {
        e.preventDefault();
    }

    backButton(e) {
        e.preventDefault();
        window.history.back();
    }

    async componentDidMount() {
        let { user, name, title } = JSON.parse(sessionStorage['authMiocuore'])
        this.setState({ user: { pk_med: user, name, title } })
        await this.onChange(new Date())
    }

    async onChange(date) {
        this.setState({ loading: true })
        await api.get('/buscaDados?data='+dateSql(date.toISOString())+'&fk_med='+this.state.user.pk_med)
			.then(async (response) => {
                let r = response.data
                let sortedItems = r.sort((a, b) => {
                    if (a.HORARIO > b.HORARIO) {
                        return 1;
                    }
                    if (b.HORARIO > a.HORARIO) {
                        return -1;
                    }
                    return 0;
                })
                this.setState({ date: date, items: sortedItems, loading: false });
			});
    }

    mapItems (item, id) {
        if (item.NOME && item.STATUS) {
            return (
                    <div key={id}>
                        <style type="text/css">
                            {`
                                .list-group-item-${id+1} { background-color: ${item.COR} !important; 
                                border-radius: 0 !important;
                            }
                            `}
                        </style>
                    
                        <ListGroup.Item action key={id} className="listGroupItem" variant={id+1}>
                            <Row>
                                <Col xs={5} md={3} style={{ alignSelf: 'center', width:'40vw', maxWidth:'150px', textAlign: 'center' }}>
                                    <p style={{ marginBottom: '2px'}}><strong>{item.HORARIO}</strong></p>
                                    <p style={{ marginBottom: '2px'}}><strong>{item.STATUS}</strong></p>
                                </Col>
                                <Col style={{fontSize: '0.8rem', alignSelf: 'center'}}>
                                    <p style={{ marginBottom: '2px' }}><strong>{item.NOME}</strong></p>
                                    <p style={{ marginBottom: '2px' }}>Exame: {item.NOMEEXAME}</p>
                                    <p style={{ marginBottom: '2px' }}>Convênio: {item.CONVENIO}</p>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </div>
            )
        } else {
            console.log(item.COR)
            return (
                <div key={id}>
                    <style type="text/css">
                        {`
                            .list-group-item-${id+1} { background-color: ${item.COR} !important; 
                            border-radius: 0 !important;
                        }
                        `}
                    </style>
                
                    <ListGroup.Item action key={id} className="listGroupItem" variant={id+1}>
                        <Row>
                            <Col xs={5} md={3} style={{ alignSelf: 'center', width:'40vw', maxWidth:'150px', textAlign: 'center' }}>
                                <p style={{ marginBottom: '2px'}}><strong>{item.HORARIO}</strong></p>
                                <p style={{ marginBottom: '2px'}}><strong>{item.STATUS}</strong></p>
                            </Col>
                            <Col style={{fontSize: '0.8rem', alignSelf: 'center'}}>
                                <p style={{ marginBottom: '2px' }}><strong>{ item.COR.trim() === 'rgba(255,2,2,0.2)' ? 'BLOQUEADO' : 'DISPONÍVEL'}</strong></p>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </div>
            )
        }
    }

    setDate(e, inc) {
        e.preventDefault()
        let date = new Date(this.state.date)
        if (inc === 0) date = new Date()
        else date.setDate(this.state.date.getDate() + inc)
        this.onChange(date)
    }

    render() {
        let listGroupItems = this.state.items.map(this.mapItems)
        return (
            <div className="app">

                <div className="containerList">

                    <div className="headerDiv">
                        <button className="backButton" onClick={(e) => {this.backButton(e)}}><Icon size={24} icon={iosArrowBack}></Icon></button>
                        <img alt="Miocuore" src={require('../imgs/logo.png')} title="Miocuore" className="menuImage"/>
                    </div>

                    <div className="itemsDiv">
                        <p className="listGroupTitle">Agenda - {this.state.user.title+'. '+this.state.user.name}</p>
                        <div className="nav">
                            <div className="dayNav">
                                <DatePicker className="datePicker" onChange={this.onChange} value={this.state.date} showLeadingZeros clearIcon={null}/>
                                <div className="navButtons">
                                    <button className="navButton" onClick={(e) => this.setDate(e, -1)}> <Icon size={20} icon={androidArrowDropleft}></Icon> </button>
                                    <button className="navButton" onClick={(e) => this.setDate(e, 0)}> Hoje </button>
                                    <button className="navButton" onClick={(e) => this.setDate(e, 1)}> <Icon size={20} icon={androidArrowDropright}></Icon> </button>
                                </div>
                            </div>
                        </div>
                        <div className={"listGroupItems"+ (this.state.items.length > 0 ? ' defaultBg' : '')}>
                            {
                                this.state.loading ? 
                                        (<div className="listGroupItems">
                                            <Spinner animation="grow" className="loading" />
                                        </div>) 
                                    : 
                                        (
                                            this.state.items.length > 0 ? 
                                                (<div className="listGroupItems defaultBg">
                                                    <ListGroup>
                                                        {listGroupItems}                                
                                                    </ListGroup>
                                                </div>) 
                                            :
                                                (<div className="listGroupItems">
                                                    <p style={{ backgroundColor: '#002e5e', color: 'white', textAlign: 'center', marginBottom: '0' }}>
                                                        Não foram encontrados agendamentos para essa data.
                                                    </p>
                                                </div>)
                                        )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Agenda;
