import React from 'react';
import '../css/Default.css';
import {LinkContainer} from 'react-router-bootstrap'
import {ListGroup, Col, Row} from 'react-bootstrap'
import { Icon } from 'react-icons-kit';
// import {view} from 'react-icons-kit/ikons/view'
import {iosArrowBack} from 'react-icons-kit/ionicons/iosArrowBack'


class Exames extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            items : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
        };
        this.mapItems = this.mapItems.bind(this)
        this.clickList = this.clickList.bind(this)
        this.openItem = this.openItem.bind(this)
        this.backButton = this.backButton.bind(this)
    }

    clickList(e) {
        e.preventDefault();
    }

    openItem(e, item, id) {
        e.preventDefault();
    }

    backButton(e) {
        e.preventDefault();
        window.history.back();
    }

    mapItems (item, id) {
        return (
            <LinkContainer to={"/miocuore/agenda/exame?id="+id}>
                <ListGroup.Item action key={id} className="listGroupItem">
                    <Row>
                        <Col xs={5} md={3} style={{ alignItems: 'left', width:'40vw', maxWidth:'50px' }}>
                            <p style={{ marginBottom: '2px' }}><strong>00:00</strong></p>
                            <p style={{ marginBottom: '2px' }}><strong>Status</strong></p>
                        </Col>
                        <Col style={{fontSize: '0.8rem', alignSelf: 'center'}}>
                            <p style={{ marginBottom: '2px' }}><strong>Nome do Paciente</strong></p>
                            <p style={{ marginBottom: '2px' }}>Exame</p>
                            <p style={{ marginBottom: '2px' }}>Convênio</p>
                        </Col>
                    </Row>
                </ListGroup.Item>
            </LinkContainer>
        )
    }

    render() {
        // let listGroupItems = this.state.items.map(this.mapItems)
        return (
            <div className="app">

                <div className="containerList">

                    <div className="headerDiv">
                        <button className="backButton" onClick={(e) => {this.backButton(e)}}><Icon size={24} icon={iosArrowBack}></Icon></button>
                        <img alt="Miocuore" src={require('../imgs/logo.png')} title="Miocuore" className="menuImage"/>
                    </div>

                    <div className="itemsDiv">
                        <p className="listGroupTitle">Exames - Em Desenvolvimento...</p>
                    </div>

                </div>
            </div>
        );
    }
}

export default Exames;
