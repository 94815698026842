import React from 'react';

import './index.css'

export default function CustomizedInputs(props) {
  return (
    <div className="mioWrapper" style={props.wrapperStyle}>
      <label className="mioLabel">{props.label}</label>
      <input {...props} className="mioInput"></input>
    </div>
  );
}