import React, { Component } from 'react';
import {LinkContainer} from 'react-router-bootstrap'


class Home extends Component {
    constructor(props, context) {
        super(props, context);
        this.logOut = this.logOut.bind(this)
    }

    logOut(e) {
        e.preventDefault();
        sessionStorage['authMiocuore'] = JSON.stringify({ authenticated: false, user: 0 })
        window.location.href = '/miocuore/login' 
    }

    render() {
        return (
            <div className="app">

                <div className="container">

                    <div className="homeImageDiv">
                        <img alt="Miocuore" src={require('../imgs/logo.png')} title="Miocuore" className="loginImage"/>
                    </div>

                    <div className="buttonsDiv">
                        <p className="menuText">Selecione a opção desejada:</p>
                        <LinkContainer to='/miocuore/agenda'>
                            <button className="homeButton" rel="nofollow"><span>Visualizar Agenda</span></button>
                        </LinkContainer><br/>
                        <LinkContainer to='/miocuore/laudos'>
                            <button className="homeButton" rel="nofollow"><span>Laudos</span></button>
                        </LinkContainer><br/>
                        <button className="homeButton" rel="nofollow" onClick={(e) => this.logOut(e)}><span>Sair</span></button>
                    </div>

                </div>
            </div>
        );
    }
}

export default Home;
