import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';
import Agenda from './pages/Agenda';
import Exames from './pages/Exames';
import Laudos from './pages/Laudos';
import Home from './pages/Home';
import './App.css';
import '../src/css/Default.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route exact path="/miocuore/login" component={Login} />
					<ProtectedRoute path="/miocuore/home" component={Home} />
					<ProtectedRoute exact path="/miocuore/agenda" component={Agenda} />
					<ProtectedRoute exact path="/miocuore/exames" component={Exames} />
					<ProtectedRoute exact path="/miocuore/laudos" component={Laudos} />
					<ProtectedRoute component={Home} />
				</Switch>
			</Router>
		);
	}
}

export default App;

