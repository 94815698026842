import React, { Component } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../services/api'

import "../css/Login.css";

const swal = withReactContent(Swal)

class Login extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        };
    }

    login(e){
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        if (data.get('user') === '') {
            swal.fire('Usuário não informado!', 'Informe o usuário.', 'warning')
            return
        }

        if (data.get('password') === '') {
            swal.fire('Senha não informada!', 'Informe a senha.', 'warning')
            return
        }
        //Converte FormData em JSON
        var object = {};

        data.forEach(function(value, key){
            object[key] = value;
        });

        //Checa no servidor
        api.post('validaLogin', object)
        .then(function (response) {
            let { validated, pk_med, name, title } = response.data;
            if (validated) {
                sessionStorage['authMiocuore'] =  JSON.stringify({ authenticated: true, user: pk_med, name, title })
                window.location.href = '/miocuore/home' 
            } else {
                swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
            }
        })
        .catch(function (error) {
            console.log(error);
        });


    

    }

    componentDidMount() {
        let strAuth = sessionStorage['authMiocuore'] || JSON.stringify({ authenticated: false, user: 0 })
        let auth = JSON.parse(strAuth) 
        if (auth.authenticated) window.location.href = '/miocuore/home'
    }

    render() {
        return (
            <div className="app">

                <div className="containerLogin">

                    <div className="rowLogin loginImageDiv">
                        <img alt="Miocuore" src={require('../imgs/logo.png')} title="Miocuore" className="loginImage"/>
                    </div>

                    <div className="rowLogin loginInputs">
                        <form className="form-signin" id="loginForm" onSubmit={this.login}>
                            <div className="form-label-group">
                                <input type="text" id="user" name="user" className="loginInput" placeholder="Usuário"/>
                                <label htmlFor="user">Usuário</label>
                            </div>

                            <div className="form-label-group">
                                <input type="password" id="password" name="password" className="loginInput" placeholder="Senha"/>
                                <label htmlFor="password">Senha</label>
                            </div>

                            <button className="loginButton" type="submit" rel="nofollow">
                                <span><p className='loginButtonText'>Entrar</p></span>
                            </button>
                        </form>
                    </div>

                </div>
            </div>
        );
    }
}

export default Login;
