import React, { useEffect, useState } from 'react';
import { Carousel, Modal } from 'react-bootstrap'
import {ListGroup, Col, Row, Spinner} from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import FrLoading from '../FrLoading'

import api from '../../services/api'

import './index.css';

const swal = withReactContent(Swal)

function FooterDelphus(props) {
    const [container, setContainer] = useState({foto_destaque: null, pk_lot: null})
    const [laudo, setLaudo] = useState({})
    const [anexos, setAnexos] = useState([])

    useEffect(() => {
        setContainer(props.container)
        console.log(props.container)
        setLaudo(props.laudo)
        console.log(props.laudo)
        setAnexos(props.anexos)
        console.log(props.anexos)
    }, [props.show])

    const downloadFile = async (e, id) => {
        e.preventDefault();
        swal.fire({
            html: <FrLoading text="Preparando arquivo para download..." />,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
        })
        await api.get('/downloadArquivoLaudo?pk_lar='+anexos[id].PK_LAR,  {responseType: 'blob'})
        .then((r) => {
            var data = new Blob([r.data]);
            var url = window.URL.createObjectURL(data);
            var a = document.createElement('a');
            a.href = url;
            a.download = anexos[id].FILENAME;
            document.body.appendChild(a);
            a.click();    
            a.remove();
            swal.close()
        }).catch(e => swal.close());
    }

    const mapItems = (item, id) => {
        return (
            <ListGroup.Item action key={id} className="listGroupItem" onClick={(e) => downloadFile(e, id)}>
                <Row>
                    <p style={{ marginBottom: '2px' }}><strong>{item.FILENAME}</strong></p>   
                </Row>
            </ListGroup.Item>
        )
    }

    let listGroupItems = anexos.length > 0 ? anexos.map(mapItems) : null
    return (
        <Modal show={props.show} onHide={props.handleClose} centered className="messageModal">
            <Modal.Header style={{ padding: '0', backgroundColor: '#811919', alignContent: 'center', fontSize: '0.9rem', display: 'flex', flexDirection: 'column', borderBottom: '3px solid black' }}> 
                <p className="swipeTitle">Nº Exame: {laudo.FK_EXA}</p>
                <p className="swipeTitle">Paciente: {laudo.NOMEPAC}</p>
            </Modal.Header>
            <Modal.Body style={{ height: '75vh', backgroundColor: '#002E5E', color: 'white', overflowY: 'auto' }}>
                <div>
                    {(container && container.html) ?
                        <div className="listDiv">
                            <iframe className="textoLaudo" style={{ backgroundColor: 'white' }} src={api.backendUrl+container.html}/>
                        </div> : <></>
                    }
                    {(anexos.length > 0) ?
                        <div className="listDiv">
                            Anexos:
                            <ListGroup>
                                {listGroupItems}                                
                            </ListGroup>
                        </div>
                        : <></>
                    }
                    
                </div>
            </Modal.Body>
            <Modal.Footer className="modalFooter" style={{ backgroundColor: '#002E5E'}}>
                <button className="modalCloseButton" onClick={props.handleClose}>Fechar</button>
            </Modal.Footer>
        </Modal>
    );
}

export default FooterDelphus;
