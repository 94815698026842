import React from 'react';
import '../css/Default.css';
import {LinkContainer} from 'react-router-bootstrap'
import {ListGroup, Col, Row, Spinner} from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group';
import { Icon } from 'react-icons-kit';
import {iosArrowBack} from 'react-icons-kit/ionicons/iosArrowBack'
import {ic_clear_all} from 'react-icons-kit/md/ic_clear_all'
import {ic_search} from 'react-icons-kit/md/ic_search'
import {ic_keyboard_arrow_up} from 'react-icons-kit/md/ic_keyboard_arrow_up'
import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down'

import MioTextInput from '../components/MioTextInput'
import DetailLaudoModal from '../components/DetailLaudoModal'

import api from '../services/api'


class Laudos extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            data: [],
            dataLaudo: {},
            filter: {},
            anexos: [],
            selectedLaudo: {},
            pk_lau: 0,
            loading: false,
            show: false,
            open: true,
        };
        this.mapItems = this.mapItems.bind(this)
        this.clickList = this.clickList.bind(this)
        this.openItem = this.openItem.bind(this)
        this.backButton = this.backButton.bind(this)
        this.search = this.search.bind(this)
        this.clearForm = this.clearForm.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.expandFilter = this.expandFilter.bind(this)
    }

    async componentDidMount() {
        
    }

    handleChange = (e) => {
        const auxValues = { ...this.state.filter };
        auxValues[e.target.name] = e.target.value;
        this.setState({ filter: auxValues });
    };

    async search(e) {
        e.preventDefault();
        const filter = { ...this.state.filter }
        if (filter && (filter.nomepac || filter.dataini || filter.datafim)) {
            this.setState({ loading: true });
            await api.get('/buscaLaudos', { params: filter })
            .then(async (response) => {
                this.setState({ data: response.data, loading: false });
            })
        } else {
            alert('Preencha algum dos parâmetros!')
        }
    }

    clickList(e) {
        e.preventDefault();
    }

    async openItem(e, item, id) {
        e.preventDefault();
        await api.get('/buscaLaudo?pk_lau='+this.state.pk_lau)
        .then(async (response) => {
            let laudo = response.data
            await api.get('/buscaArquivosLaudo?pk_lau='+this.state.pk_lau)
            .then(async (response) => {
                let anexos = response.data
                this.setState({ dataLaudo: laudo[0], anexos });
            });
        });
    }

    clearForm(e) {
        e.preventDefault();
        this.setState({ filter: {}, data: [] });
    }

    backButton(e) {
        e.preventDefault();
        window.history.back();
    }

    async handleShow(e, id) { 
        e.preventDefault()
        this.setState({ loading: true})
        await api.get('/buscaLaudo', { params: { pk_lau: this.state.data[id].PK_LAU }})
        .then(async (response) => {
            console.log(response.data[0])
            let laudo = response.data
            await api.get('/buscaArquivosLaudo', { params: { pk_lau: this.state.data[id].PK_LAU }})
            .then(async (response) => {
                let anexos = response.data
                this.setState({ show: true, dataLaudo: laudo[0], loading: false, selectedLaudo: this.state.data[id], anexos})
            });
            
        })
    };

    mapItems (item, id) {
        return (
            <ListGroup.Item action key={id} className="listGroupItem" onClick={(e) => this.handleShow(e, id)}>
                <Row>
                    <Col xs={5} md={3} style={{ alignItems: 'left', width:'40vw', maxWidth:'150px' }}>
                        <p style={{ marginBottom: '2px' }}><strong>{new Date(item.DATA).toLocaleDateString()}</strong></p>
                        <p style={{ marginBottom: '2px' }}><strong>Nº Exame: {item.FK_EXA}</strong></p>
                    </Col>
                    <Col style={{fontSize: '0.8rem', alignSelf: 'center'}}>
                        <p style={{ marginBottom: '2px' }}><strong>Nome do Paciente: {item.NOMEPAC}</strong></p>
                        <p style={{ marginBottom: '2px' }}>Procedimento: {item.NOME_PROCEDIMENTO}</p>
                        <p style={{ marginBottom: '2px' }}>Solicitante: {item.NOMECRM}</p>
                        <p style={{ marginBottom: '2px' }}>Executante: {item.NOMEMED}</p>
                        <p style={{ marginBottom: '2px' }}>Convênio: {item.NOMECVN}</p>
                    </Col>
                </Row>
            </ListGroup.Item>
        )
    }

    handleClose(){ this.setState({ show: false, dataLaudo: {}, selectedLaudo: {} }) };

    expandFilter(e) {
        e.preventDefault()
        this.setState({ open: !this.state.open })
    }

    render() {
        let listGroupItems = this.state.data.map(this.mapItems)
        return (
            <div className="app">
                <DetailLaudoModal container={this.state.dataLaudo} laudo={this.state.selectedLaudo} anexos={this.state.anexos} show={this.state.show} handleClose={this.handleClose}/>
                <div className="containerList">

                    <div className="headerDiv">
                        <button className="backButton" onClick={(e) => {this.backButton(e)}}><Icon size={24} icon={iosArrowBack}></Icon></button>
                        <img alt="Miocuore" src={require('../imgs/logo.png')} title="Miocuore" className="menuImage"/>
                    </div>

                    <div className="itemsDiv">
                        <p className="listGroupTitle">Laudos</p>
                        <form onSubmit={this.search}>

                            <div className="nav" style={{ display: this.state.open ? 'flex' : 'none', padding: '10px', justifyContent: 'flex-start' }}>
                                
                                    <MioTextInput
                                        value={this.state.filter.nomepac || ''} 
                                        wrapperStyle={{ maxWidth: '400px', width: '100%' }} 
                                        name="nomepac" 
                                        id="nomepac" 
                                        color="#811919" 
                                        size="small"
                                        label='Paciente' 
                                        onChange={this.handleChange}
                                    />
                                    <MioTextInput
                                        value={(this.state.filter.dataini || '').split('T')[0]}
                                        onChange={this.handleChange}
                                        name="dataini"
                                        id="dataini"
                                        color="#49573B"
                                        label="Data Inicial"
                                        size="small"
                                        wrapperStyle={{ maxWidth: '200px', width: 'calc(48% - 20px)', flex: 1 }}
                                        type="date"
                                    />
                                    <MioTextInput
                                        value={(this.state.filter.datafim || '').split('T')[0]}
                                        onChange={this.handleChange}
                                        name="datafim"
                                        id="datafim"
                                        color="#49573B"
                                        label="Data Final"
                                        size="small"
                                        wrapperStyle={{ maxWidth: '200px', width: 'calc(48% - 20px)', flex: 1 }}
                                        type="date"
                                    />
                                    {/* <div className="navButtonsSearch"> */}
                                        <button className="navButtonSearch" onClick={this.search}><Icon size={24} icon={ic_search} style={{ height: '30px' }}/> Buscar </button>
                                        <button className="navButtonSearch" onClick={this.clearForm}><Icon size={24} icon={ic_clear_all} style={{ height: '30px' }}/> Limpar </button>
                                    {/* </div> */}
                            </div>
                        </form>
                        <button className="navShowHideButton" onClick={this.expandFilter}><Icon size={24} icon={this.state.open ? ic_keyboard_arrow_up : ic_keyboard_arrow_down }/></button>

                        <div className={"listGroupItems"+ (this.state.data ? ' defaultBg' : '')}>
                            {
                                this.state.loading ? 
                                        (<div className="listGroupItems">
                                            <Spinner animation="grow" className="loading" />
                                        </div>) 
                                    : 
                                        (
                                            this.state.data ? 
                                                (<ListGroup>
                                                    {listGroupItems}                                
                                                </ListGroup>)
                                            :
                                                (<div className="listGroupItems">
                                                    <p style={{ backgroundColor: '#002e5e', color: 'white', textAlign: 'center', marginBottom: '0' }}>
                                                        Não foram encontrados laudos com esses parâmetros informados.
                                                    </p>
                                                </div>)
                                        )
                            }
                        </div>
                    </div>

                    
                </div>
            </div>
        );
    }
}

export default Laudos;
