import axios from 'axios';

// let backendUrl = 'https://780e06b6507a.sn.mynetname.net:21001'
// let backendUrl = 'http://192.168.0.10:21001'
let backendUrl = 'https://apps.delphus.inf.br:21020'


const config = {
	protocol: window.location.protocol,
	server: window.location.hostname,
	// server: 'localhost',
	port: 3000,
	portBackend: 21001,
	frontend: 'http://localhost.com:3000'
};


const api = axios.create({
	baseURL: backendUrl + '/api/'
});

api.backendUrl = backendUrl

export default api